"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  slideTitle1: 'Система управления домом и поселком',
  slideText1: 'Мы делаем сервис для того, чтобы собственники и управляющие компании находили общий язык, а качество жизни улучшалось',
  slideTitle2: 'Контролируйте бюджет',
  slideSubtitle2: 'Сквер Позволяет:',
  slideText2Line1: '- Понять на что расходуются средства в доме',
  slideText2Line2: '- Участвовать в составлении и принятии бюджета',
  slideText2Line3: '- Проверять соответствие фактических расходов с планом и анализировать отклонения',
  slideTitle3: 'Обращения',
  slideText3Line1: 'В Сквере реализован механизм обращений, который позволяет управляющим компаниям оперативнее обрабатывать обращения собственников и жителей дома, а также анализировать эффективность работы своих собственных сотрудников.',
  slideText3Line2: 'Для жителей этот функционал позволяет оперативно решать возникающие проблемы и не тратить зря время. Всегда можно посмотреть кто? что? и когда? сделал по обращению.'
};