"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  mixed: {
    default: 'Неверное значение',
    required: 'Обязательное поле',
    oneOf: 'Неподходящий вариант',
    notOneOf: 'Недопустимое значение'
  },
  string: {
    length_0: 'Поле должно содержать {{count}} символ',
    length_1: 'Поле должно содержать {{count}} символа',
    length_2: 'Поле должно содержать {{count}} символов',
    min: 'Мин. кол-во символов: ${min}',
    max: 'Максимум ${max} символов',
    matches: 'Не подходит под маску: "${regex}"',
    email: 'Введен невалидный адрес электронной почты',
    url: 'Некорректный URL',
    trim: 'Поле не должно начинаться и заканчиваться пробелами',
    lowercase: 'Поле должно быть в нижнем регистре',
    uppercase: 'Поле должно быть в верхнем регистре'
  },
  number: {
    min: 'Значение должно быть меньше или равно ${min}',
    max: 'Значение должно быть больше или равно ${max}',
    lessThan: 'Значение должно быть строго меньше ${less}',
    moreThan: 'Значение должно быть строго больше ${more}',
    positive: 'Значение должно быть положительным',
    negative: 'Значение должно быть отрицательным',
    integer: 'Значение должно быть целым числом'
  },
  date: {
    min: 'Дата должна быть позже {{date}}',
    max: 'Дата должна быть ранее {{date}}'
  },
  object: {
    noUnknown: 'Объект не должен содержать лишних полей'
  },
  array: {
    min_0: 'Необходимо указать мин. {{count}} элемент',
    min_1: 'Необходимо указать мин. {{count}} элемента',
    min_2: 'Необходимо указать мин. {{count}} элементов',
    max_0: 'Необходимо указать только один элемент',
    max_1: 'Необходимо указать макс. {{count}} элемента',
    max_2: 'Необходимо указать макс. {{count}} элементов'
  }
};