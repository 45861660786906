"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  lastActives: 'Последняя активность',
  thisHouseAlreadyRegistered: 'Ух ты! Такой дом уже зарегистрирован в Сквере!',
  noteOnlyOne: 'И даже не один!',
  selectHouseToJoin: 'Выберите дом, к которому хотите присоединиться',
  registeredNewHouse: 'В Сквере пока нет такого дома. Хотите зарегистрировать новый Объект?',
  findHouse: 'Найти свой дом',
  system: 'Система управления домом и поселком',
  weDoServices: 'Мы делаем сервис для того, чтобы собственники и управляющие компании находили общий язык, а качество жизни улучшалось',
  controlBudjets: 'Контролируйте бюджет',
  sqvrAllows0: 'Сквер Позволяет:',
  sqvrAllows1: '- Понять на что расходуются средства в доме',
  sqvrAllows2: '- Участвовать в составлении и принятии бюджета',
  sqvrAllows3: '- Проверять соответствие фактических расходов с планом и анализировать отклонения',
  hasComplaitns: ' В Сквере реализован механизм обращений, который позволяет управляющим компаниям оперативнее обрабатывать обращения собственников и жителей дома, а также анализировать эффективность работы своих собственных сотрудников.',
  solveProblems: 'Для жителей этот функционал позволяет оперативно решать возникающие проблемы и не тратить зря время. Всегда можно посмотреть кто? что? и когда? сделал по обращению.',
  backToStep: 'Вернуться на шаг',
  deleteDataAboutHouse: 'Удалить данные об этом доме?',
  awaitAnswer: 'Администратор дома получил вашу заявку на присоединение. Ожидайте ответа.',
  createAnotherHouse: 'Создать альтернативный дом?',
  recomendedDoThisIf: 'Рекомендуем это делать только, если ранее созданные Объекты Вам не подходят',
  second: 'Второй',
  third: 'Третий',
  fourth: 'Четвертый',
  fifth: 'Пятый',
  sixth: 'Шестой',
  seventh: 'Седьмой',
  eighth: 'Восьмой',
  ninth: 'Девятый',
  tenth: 'Десятый',
  eleventh: 'Одиннадцатый',
  twelfth: 'Двенадцатый',
  thirteenth: 'Тринадцатый',
  fourteenth: 'Четырнадцатый',
  addHouse: 'добавить еще один дом',
  user: 'О себе',
  realty: 'Недвижимость',
  login: 'Продолжить с помощью',
  activate: 'Код регистрации',
  addressNotFound: ' Адрес не найден',
  alreadyRegistered: 'уже зарегистрирован!',
  positionAlreadyRegistered: 'Позиция уже зарегистрирована!',
  continueSearch: 'Продолжить расширенный поиск',
  search: 'Если Вы не видите подходящие варианты, воспользуйтесь расширенным поиском для более точного ввода адреса.',
  changeSearch: 'Вы можете переключить поиск дома или поселка на поиск по его названию. Это улучшит результаты поиска уже зарегистрированных в Сквере объектов',
  selectHoa: 'Вы можете переключить на поиск дома или поселка только по адресу',
  advancedSearch: 'Расширенный поиск',
  street: 'ул',
  addNew: 'Создать новый',
  city: 'Город',
  cityDistrict: 'Городской округ',
  federalSignificance: 'Город федерального значения',
  settlement: 'Поселок городского типа',
  town: 'Городок',
  urbanSettlement: 'городской поселок',
  password: 'Пароли должны совпадать',
  userAlreadyRegistered: 'Ваш пользователь уже зарегистрирован в Сквере',
  youCanAddNew: 'Вы можете добавить новый объект в уже существующий аккаунт для этого подтвердите данное действие и нажмите "Добавить"',
  youCanCreateNew: 'Вы можете создать новый аккаунт для добавления объекта, для этого зарегистрируйтесь в Сквер c другого социального аккаунта',
  wrongLogin: 'Неправильный логин или пароль',
  errorLogin: 'Ошибка авторизации через соц. сеть. Попробуйте ещё раз, если ошибка повторяется, ' + 'то свяжитесь с технической поддержкой или зарегистрируйтесь через email',
  inSelectedRoom: 'В выбранном помещении недостаточно свободной площади',
  format5: 'значение должно быть числом',
  format9: 'Площадь помещения не может быть больше, чем площадь всех помещений в здании',
  unknownType: 'Неизвестный тип собственности',
  selectUsageType: 'Укажите тип недвижимости',
  selectOwnerType: 'Выберите тип собственности',
  selectAddress: 'Выберите адрес дома',
  selectNeededYouAddress: 'В данном товариществе зарегистрировано несколько объектов с разными адресами, выберите нужный вам дом',
  apartmentNumber: 'Номер помещения',
  totalArea: 'Общая площадь',
  ownershipShare: 'Доля',
  selectFormVillage: 'Укажите тип недвижимости',
  usageTypeOther: 'Введите тип недвижимости',
  houseNumber: 'Номер дома',
  buildingNumber: 'Номер строения',
  landNumber: 'Номер участка',
  appartment: 'Квартира',
  moreInfo: 'Доп. информация',
  accepted: 'Я принимаю',
  policy: 'условия использования и политику конфиденциальности',
  addInfo: 'Теперь Вам осталось заполнить информацию о себе и своей собственности, чтобы Администратор мог с Вами связаться и принять Вашу заявку на присоединение. Обязательные поля отмечены *.',
  infoAboutUk: 'Информация об УК',
  youSelectRole: '<p>Вы выбрали роль Управляющего, что означает, что Вы не имеете собственности в данном объекте, т.к. представляете управляющую компанию. Нажмите "Вернуться", если Вы являетесь собственником.</p> <p>Регистрируя объект в Сквере, Вы в любом случае будете администратором Вашего объекта, и получите полный набор полномочий.</p>',
  appartementBuilding: 'Многоквартирный дом',
  settlement1: 'Поселок',
  selfGoverment: 'Самоуправление',
  partnershipOwner: 'Товарищество собственников',
  tsj: 'ТСЖ, ТСН, ГСК, ЖСК, кооперативы и др.',
  managementCompany: 'Управляющая компания',
  living: 'Жилое помещение',
  noLiving: 'Нежилое помещение',
  room: 'Комната в ком. квартире',
  land: 'Земельный участок',
  townhouse: 'Квартира в Таунхаусе',
  share: 'Долевая',
  join: 'Совместная',
  fraction: 'Дробь',
  used: 'Ваш e-mail уже зарегистрирован в СКВЕР',
  usedInHOA: 'Ваш e-mail уже зарегистрирован в данном объекте',
  notUsed: 'E-mail не зарегистрирован в СКВЕР',
  continueWith: 'Продолжить с помощью',
  withEmail: 'или через email',
  withSocial: 'либо через социальные сети',
  incorrectResponse: 'Был отправлен некорректный запрос на сервер. Пожалуйста, попробуйте ещё раз через 5 минут или свяжитесь с технической поддержкой.',
  createJoinRequestTitle: 'Заявка на присоединение была отправлена Администратору объекта %NAME%',
  createJoinRequestMsg: 'В случае ее одобрения, Вы получите сообщение с кодом регистрации на эл.почту или по смс.\nСейчас вы будете перенаправлены на страницу объекта как гость.',
  socialNotFoundTitle: 'С указанным e-mail не связан ни один аккаунт в соц. сетях',
  socialNotFoundMsg: 'Чтобы продолжить Вам необходимо ввести пароль от указанного e-mail',
  emailNotAttachedToSocialTitle: 'Указанный e-mail принадлежит аккаунту связанному с соц. сетью',
  emailNotAttachedToSocialMsg: 'Чтобы продолжить Вам необходимо авторизоваться через социальную сеть',
  accountAlreadyExistsTitle: 'Невозможно присоединиться повторно',
  accountAlreadyExistsMsg: '<p>Вы уже присоединились к данному объекту с этим аккаунтом социальной сети. Авторизуйтесь или используйте иной аккаунт, если вам действительно нужно присоединиться</p>',
  unknown: 'Что-то пошло не так, попробуйте снова через 5 минут или свяжитесь с технической поддержкой',
  unknownSubdomen: 'Что-то пошло не так, попробуйте снова через 5 минут или свяжитесь с технической поддержкой. Не удалось получить код объекта.',
  owner: 'Собственник',
  ownerRole: 'В Сквере роль собственника предполагает участие в собраниях и принятие решений по вопросам повестки дня, а также получение отчетов и информации об объекте.',
  manager: 'Управляющий',
  managerRole: 'Управляющий это не собственник. Это сотрудник УК, сотрудник Товарищества собственников. Управляющий не может голосовать на собраниях. Если вы одновременно являетесь и собственником помещения и сотрудником Товарищества собственников, то выбирайте «Собственник»',
  contractor: 'Подрядчик',
  contractorRole: 'В Сквере подрядчики ...',
  expert: 'Эксперт',
  expertRole: 'Эксперт это не собственник. Это сотрудник УК, сотрудник Товарищества собственников.',
  avatarError: 'Размер подгружаемого файла должен быть не более 10Мб',
  uploadAvatar: 'Загрузить фото'
};