"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  yourTariffShort: 'Ваш тариф',
  tariffs: 'Тарифы',
  allPlans: 'Все тарифы',
  payment: 'Оплата',
  limits: 'Лимиты',
  meetings_0: '{{count}} собрание',
  meetings_1: '{{count}} собрания',
  meetings_2: '{{count}} собраний',
  polls_0: '{{count}} опрос',
  polls_1: '{{count}} опроса',
  polls_2: '{{count}} опросов',
  messages_0: '{{count}} сообщение',
  messages_1: '{{count}} сообщения',
  messages_2: '{{count}} сообщений',
  mailings: 'E-mail рассылки',
  smsMailings: 'SMS рассылки',
  editingBulletinPossibility: 'Возможность редактирования бюллетеней',
  additionalMailings: 'а также автоматические рассылки сервиса',
  mettingTemplatesAccess: 'Доступ к шаблонным собраниям и опросам',
  massDocumentsSigning: 'Массовое подписание документов с ПЭП (простая электронная подпись)',
  perYear: 'в год',
  buyTariff: 'Купить',
  buyTariffLimitsPage: 'Купить тариф',
  payUpToTariff: 'Доплатить до тарифа',
  free: 'Бесплатный',
  standart: 'Стандарт',
  business: 'Бизнес',
  premium: 'Премиум',
  selectTariff: 'Выберите подходящий Вам тарифный план.',
  yourTariff: 'Ваш тарифный план',
  limitIncrease: 'Расширение лимитов',
  limitIncreasePrice: 'Общая стоимость выбранных новых лимитов',
  limitIncreaseDifferenceComment: 'Новые лимиты будут доступны сразу после оплаты, расширив лимиты текущего тарифа.',
  upTo: 'до {{date}}',
  daysLeft_0: 'остался {{count}} день',
  daysLeft_1: 'осталось {{count}} дня',
  daysLeft_2: 'осталось {{count}} дней',
  trialIsActiveLabel: 'Пробный тариф активирован',
  selectTariffExtended: 'Выберите интересующий Вас тариф.',
  selectTariffComment: 'Вы можете или снова купить уже действующий тарифный план или выбрать новый. Тогда все неиспользованные лимиты будут добавлены к новому тарифному плану. Действовать тарифный план начнет сразу после оплаты. Срок действия - 1 год.',
  selectNewTariff: 'Купить новый тариф',
  paymentLimitIncreaseTitle: 'Оплатить расширение лимитов',
  selectNewTariffComment: 'Вы сможете или снова купить уже действующий тарифный план или выбрать новый. Тогда все неиспользованные лимиты будут добавлены к новому тарифному плану. Действовать тарифный план начнет сразу после оплаты. Срок действия - один год.',
  payUpToNewTariff: 'Доплатить до нового тарифа',
  payUpToNewTariffComment: 'Вы сможете оплатить разницу в цене между Вашим действующим тарифным планом и новым тарифным планом. При выборе доплаты, вы увеличиваете лимиты и не изменяете срок действия существующего тарифа. Обратите внимание, что Доплатить до нового тарифа можно только при выборе более дорогого тарифа.',
  a: 'А)',
  b: 'Б)',
  unlimitMettings: 'Неограниченное количество собраний',
  meetingsAreUnlimited: 'Собрания не ограничены',
  unlimitPolls: 'Неограниченное количество опросов',
  unlimitEmails: 'Неограниченное количество сообщений',
  currentPaymentMethod: 'Текущий способ оплаты',
  customizePaymentMethod: 'Настроить способ оплаты',
  payedUpTo: 'Тариф оплачен до {{date}}',
  tariffAutoRenewal: 'Автоматическое продление тарифа',
  willDebitedFromCard: '{{date}} с карты будет списана стоимость тарифа',
  paymentHistory: 'История платежей',
  noPaymentHistory: 'Нет истории платежей',
  tariffLimits: 'Лимиты тарифа',
  includedInTariff: 'Включено в тариф',
  used: 'Использовано',
  unlimit: 'Неограниченно',
  notAvailable: 'Не доступно',
  available: 'Доступно',
  emailCount: 'Количество произвольных e-mail',
  smsCount: 'Количество SMS',
  meetingsCount: 'Количество проведённых собраний',
  pollsCount: 'Количество проведённых опросов',
  bulletinEdit: 'Редактирование бюллетеней',
  templateMeetingsAvailability: 'Доступность шаблонных собраний и опросов',
  registryActualization: 'Актуализация данных реестра собственников',
  userItemPercents_0: 'Использован {{count}}% {{item}}',
  userItemPercents_1: 'Использовано {{count}}% {{item}}',
  userItemPercents_2: 'Использовано {{count}}% {{item}}',
  limitIsOver: 'Лимит исчерпан. Купите тариф с большим количеством {{item}}',
  usedMessages: 'сообщений',
  usedMeetings: 'собраний',
  usedPolls: 'опросов',
  yourCurrentTariff: 'Ваш действующий тариф',
  youHaveSelectedTariff: 'Вы выбрали тарифный план',
  payUpDiffComment: 'Вам нужно доплатить {{diff}} разницу между стоимостью текущего тарифного плана Стандарт {{current}} и нового тарифного плана Бизнес {{new}}.',
  payUpDiffLimitsComment: 'Срок действия и уже использованные лимиты не изменятся, но увеличатся лимиты, включенные в новый тариф.',
  willBeInNewTariff: 'Будет в новом тарифе',
  haveNow: 'Есть сейчас',
  willBeAfterBuy: 'Будет после покупки',
  includedInNewTariff: 'Включено в новый тариф',
  limitIncreaseTariffDiffColumnTitle: 'Выбранные расширения лимитов',
  willTransferFromOldTariff: 'Перейдет из старого тарифа',
  noGoBack: 'Нет, вернуться назад',
  buyingTariffLimitsComment1: 'Новый тарифный план начнет действовать сразу после оплаты. Срок действия — 1 год.',
  buyingTariffLimitsComment2: 'Все неиспользованные лимиты предыдущего тарифного плана будут добавлены к лимитам выбранного тарифного плана. Объем предоставленного хранилища файлов сохранится.',
  yourOrder: 'Ваш заказ',
  tariff: 'Тариф',
  payUpToTariff2: 'Доплата до тарифа',
  tariffValidityPeriod: 'Срок действия тарифа',
  limitValidityPeriod: 'Срок действия лимитов',
  paymentFailure: 'Оплата не прошла',
  paymentFailureMessage: 'Платеж не был произведен и деньги не были списаны. Пожалуйста произведите оплату снова.\nПри возникновении подобной проблемы снова напишите на эл. почту: {{email}}',
  paymentSuccess: 'Вы успешно оплатили заказ!',
  paymentSuccessEmailMessage: 'На ваш e-mail: {{email}} отправлены все данные по заказу.',
  paymentSuccessPhoneMessage: 'На ваш телефон: {{phone}} отправлены все данные по заказу.',
  autoRenewalWarning1: 'Вы включаете автопродление тарифа: {{tariff}}',
  autoRenewalWarning2: '{{date}} с карты {{card}} будет списана плата в размере {{sum}}.',
  autoRenewalTurnOffMessage: 'Вы успешно отключили автопродление тарифа!',
  autoRenewalTurnOffWarning1: 'Вы отключаете автопродление тарифа: {{tariff}}',
  autoRenewalTurnOffWarning2: '{{date}} будет осуществлен автоматический перевод на бесплатный тариф.',
  tariffPayUpComment1: 'Вам нужно доплатить',
  tariffPayUpComment2: 'разницу между стоимостью текущего тарифного плана',
  tariffPayUpComment3: 'и нового тарифного плана',
  tariffPayUpComment4: 'Срок действия тарифа останется прежний - “{{date}}”.',
  tariffPayUpComment5: 'Лимиты увеличатся.',
  pollsLimitExceed: 'Лимит по опросам согласно тарифному плану уже исчерпан. Вы не можете проводить опросы. Вам нужно перейти в <a href="{{link}}">тарифы</a> и купить другой тариф.',
  templatePollsAccessDenied: 'По текущему тарифу нет доступа к шаблонам опросов. Вам нужно перейти в <a href="{{link}}">тарифы</a> и купить другой тариф.',
  emailAndSmsLimitsExceed: 'Вы не можете провести e-mail/sms рассылку, так как в текущем тарифе весь лимит исчерпан. Для отправки выбранных сообщений нужно сначала оплатить новый <a href="{{link}}">тариф</a>',
  emailLimitExceed: 'Вы не можете провести e-mail рассылку, так как по текущему тарифу у Вас не хватает лимита для рассылки e-mail. Вам нужно перейти в <a href="{{link}}">тарифы</a> и купить другой тариф',
  smsLimitExceed: 'Вы не можете провести sms рассылку, так как по текущему тарифу у Вас не хватает лимита для рассылки sms. Вам нужно перейти в <a href="{{link}}">тарифы</a> и купить другой тариф',
  emailLimitExceedWithoutLink: 'Вы не можете провести e-mail рассылку, так как по текущему тарифу у Вас не хватает лимита для рассылки e-mail. Вам нужно перейти в "тарифы" и купить другой тариф',
  smsLimitExceedWithoutLink: 'Вы не можете провести sms рассылку, так как по текущему тарифу у Вас не хватает лимита для рассылки sms. Вам нужно перейти в "тарифы" и купить другой тариф',
  buyTariffTooltip: 'Все неиспользованные лимиты текущего тарифа будут добавлены к новому тарифу. Действовать новый тарифный план начнет сразу после оплаты. Срок его действия - один год.',
  payUpTariffTooltip: 'Вы сможете оплатить разницу в цене между действующим тарифным планом и более дорогим тарифным планом. Все  неиспользованные лимиты действующего тарифного плана будут увеличены на разницу между лимитами по действующему и новому тарифному плану. Срок действия тарифа не изменяется.',
  notIncludedInTariff: 'Не включено в тариф',
  townSettingsIsWrong: 'Для покупки тарифа нужно правильно заполнить настройки дома.',
  needCadastralCode: 'Для  расчета стоимости тарифа необходим  кадастровый номер МКД. Перейти в Настройки, чтобы ввести кадастровый номер.',
  notAvailableInFreeTariff: 'В бесплатный тариф не включено',
  pollAndMeetingTemplate: 'Шаблоны собраний и опросов',
  includedInPaiTariffs: 'Все эти опции включены в платные тарифы',
  unusedLimits: 'Текущие лимиты',
  limitsDiff: 'Разница в лимитах',
  willBeAfterPayUp: 'Будет после доплаты',
  willBeAfterPay: 'Будет после оплаты',
  messageLimitsOverheadMessage: 'Вы выбрали {{selectedMessages}}, но в текущем тарифе доступна отправка только {{availableMessages}}. Вы можете уменьшить количество получателей или оплатить новый <a href="{{link}}">тариф</a>',
  emailMessagesSelected_0: '{{count}} сообщение для e-mail рассылки',
  emailMessagesSelected_1: '{{count}} сообщения для e-mail рассылки',
  emailMessagesSelected_2: '{{count}} сообщений для e-mail рассылки',
  smsMessagesSelected_0: '{{count}} сообщение для sms рассылки',
  smsMessagesSelected_1: '{{count}} сообщения для sms рассылки',
  smsMessagesSelected_2: '{{count}} сообщений для sms рассылки',
  emailMessagesAvailable_0: '{{count}} e-mail сообщения',
  emailMessagesAvailable_1: '{{count}} e-mail сообщений',
  emailMessagesAvailable_2: '{{count}} e-mail сообщений',
  smsMessagesAvailable_0: '{{count}} sms сообщения',
  smsMessagesAvailable_1: '{{count}} sms сообщений',
  smsMessagesAvailable_2: '{{count}} sms сообщений',
  trialTariff: 'Пробный тариф',
  trialTariffsMessage: 'Если Вы не уверены в пользе премиальных функций, то перед покупкой Вы можете бесплатно попробовать их. Для этого активируйте “<b>пробный тариф</b>”. Эта функция позволит Вам до конца действия текущего тарифа использовать следующие возможности: <b>шаблоны опросов и собраний</b>, <b>редактирование бюллетеня в собрании</b>, <b>создание петиций и претензий</b>, <b>рассылка уведомлений (10 email и 10 sms)</b>',
  willTryLater: 'Попробую позже',
  activateTrialTariff: 'Активировать пробный тариф',
  from: 'от ',
  customTariffTooltip1: 'Подходит для опытных организаторов собраний или для небольших Объектов.',
  customTariffTooltip2: 'Рекомендованные лимиты зависят от количества ',
  customTariffTooltipCondo: 'помещений в МКД.',
  customTariffTooltipVillage: 'строений в посёлке.',
  customTariffTooltip3: 'Выберите нужные для Вас опции. Вы можете отказаться от использования рекомендованных опций, уменьшить или увеличить любую опцию и таким образом изменить стоимость.',
  customTariffTooltip4: 'Не включено:',
  notInclude1: `шаблонные собрания;`,
  notInclude2: 'редактирование бюллетеней;',
  notInclude3: 'массовое подписание документов;',
  notInclude4: 'петиции;',
  notInclude5: 'претензии к РСО и УК.',
  includeInTariff: 'Включено в тариф:',
  meetings1: 'актуализация реестра;',
  meetings2: 'распознавание бюллетеней;',
  meetings3: 'хранение сканкопий документов собрания в течение срока действия тарифа;',
  meetings4: 'шаблоны собраний;',
  meetings5: 'редактирование бюллетеней',
  emailInfo: '{{count}} сообщений в дополнение к автоматическим рассылкам',
  smsInfo: '{{count}} сообщений в дополнение к автоматическим рассылкам',
  signing1: 'Массовое подписание документов ',
  signing2: '(простая электронная подпись)',
  petitions: 'Петиции',
  pretensions: 'Претензии к РСО и УК',
  customName: 'Отдельные лимиты',
  recommendationHighlightLabel: 'Рекомендация',
  calculatorCustomTariffIncreaseLimitsHintTitle: 'Вы можете приобрести дополнительные тарифные опции. ',
  calculatorCustomTariffIncreaseLimitsHintBody: 'Выберите нужные Вам лимиты. После оплаты Ваши текущие лимиты тарифа будут расширены. ',
  calculatorCustomTariffCustomTariffHint: 'Выберите нужные Вам лимиты. Новый тарифный план начнет действовать сразу после  оплаты. Срок действия 1 год. ',
  calculatorCustomTariffRecommendationHint1: 'При выборе лимитов обращайте внимание на строки обозначенные как',
  calculatorCustomTariffRecommendationHint2: 'Они рассчитаны исходя из опыта проведения собраний в аналогичных домах и посёлках.',
  calculatorHintMessage2: 'Они рассчитаны исходя из опыта проведения собраний в аналогичных домах и посёлках.',
  calculatorLimitsNameColumnTitle: 'Лимиты тарифа',
  calculatorAvailableLimitsColumnTitle: 'Доступные сейчас',
  calculatorCustomLimitSelectColumnTitle: 'Выберите подходящие Вам лимиты',
  calculatorCustomLimitPriceColumnTitle: 'Стоимость',
  calculatorNewLimitsAfterPurchaseColumnTitle: 'Будет доступно после оплаты',
  calculatorAvailableStorageLimitHint: 'Из них свободно: ',
  calculatorAvailableStorageLimitHintShort: 'Свободно: ',
  calculatorCustomLimitSelectBlankValue: 'Не выбран',
  calculatorCustomLimitSelectDefaultStorageValue: 'Текущие {{limit}}',
  calculatorCustomLimitSelectMaxStorageValue: 'Максимальные {{limit}}',
  calculatorCustomLimitTotalPriceLabel: 'Итого стоимость',
  calculatorButtonGoBack: 'Отмена',
  calculatorButtonBuyTariff: 'Оплатить',
  meetingsLimitName: 'Проведение собрания',
  actializationLimitName: 'Актуализация реестра',
  bulletinsLimitName: 'Распознавание бюллетеней',
  filesLimitName: 'Хранение файлов',
  smsLimitName: 'SMS рассылки',
  emailLimitName: 'E-mail рассылки',
  pollsLimitName: 'Проведение опросов',
  pepLimitName: 'ПЭП (простая электронная подпись)',
  meetingsTooltip1: 'Вы можете провести собрание и обработать все бюллетени самостоятельно без использования других лимитов.',
  meetingsTooltip2: 'Будет доступно:',
  meetingsTooltip3: 'Формирование повестки дня;',
  meetingsTooltip4: 'Все печатные формы документов собрания;',
  meetingsTooltip5: 'Автоматические рассылки;',
  meetingsTooltip6: 'Подсчет голосов и итоговый бюллетень.',
  meetingsTooltip7: 'Будет недоступно:',
  meetingsTooltip8: 'Самостоятельные e-mail и sms рассылки;',
  meetingsTooltip9: 'Распознавание бюллетеней;',
  meetingsTooltip10: 'Хранение всех файлов собрания в течении года;',
  meetingsTooltip11: 'Актуализация реестра собственников',
  storageTooltip1: 'В ходе проведения собрания пользователи могут загружать в систему разные файлы: сканкопии бюллетеней и материалы собрания, договоры, документы, сметы и т.д.. Для хранения загруженных файлов используется дисковое пространство. Вы можете оплатить нужное количество Гб, и мы в течении 1 (Одного) года будем показывать и давать доступ ко всем этим файлам.',
  storageTooltip2: 'Рекомендуемый объем дискового пространства для Вашего ',
  town: 'дома - ',
  village: 'поселка - ',
  storageTooltip3: 'Вы можете выбрать больше или меньше. Если Вам будет не хватать этого дискового пространства, Вы всегда сможете докупить нужное количество.',
  storageTooltip4: 'Мы прекратим хранение файлов через 30 дней после окончания действия тарифа.',
  bulletinsTooltip1: 'Если Вы планируете, что в Вашем собрании будет использоваться большое количество бумажных бюллетеней, то рекомендуем Вам оплатить лимит по распознаванию бюллетеней.',
  bulletinsTooltip2: 'Как это работает? Организаторы собрания получают подлинные подписанные бюллетени, фотографируют их или сканируют и загружают в систему эти файлы. После этого Сквер автоматически распознает кто именно и как проголосовал по вопросам повестки дня и прикрепляет файлы к каждому отдельному участнику голосования. Председателю и Секретарю собрания остается просмотреть и утвердить итоги голосования по каждому участнику.',
  bulletinsTooltip3: 'Вы сможете оплатить этот функционал позднее, когда будете проводить собрание.',
  smsTooltip1: 'В дополнении к автоматическим рассылкам, пользователь может сам выбирать кому и когда отослать СМС рассылки с напоминаниями о собрании.',
  smsTooltip2: 'Рекомендованное количество sms сообщений для Вашего ',
  smsTooltip3: '{{count}} шт',
  smsTooltip4: 'Вы сможете отправить сообщения всем зарегистрированным пользователям, у которых будет сохранена информация о номерах мобильных телефонов.',
  smsTooltip5: 'Если Вам будет не хватать sms рассылок, Вы всегда сможете выбрать и оплатить дополнительный пакет.',
  emailTooltip1: 'В дополнении к автоматическим рассылкам, пользователь может сам выбирать кому и когда отослать e-mail рассылки с напоминаниями о собрании.',
  emailTooltip2: 'Рекомендованное количество e-mail сообщений для Вашего ',
  emailTooltip3: 'Вы сможете отравить сообщения всем пользователям у которых будет сохранена информация об адресах электронной почты.',
  emailTooltip4: 'Если Вам будет не хватать e-mail рассылок, Вы всегда сможете выбрать и оплатить дополнительный пакет.',
  actualizeTooltip1: 'Если Вы хотите актуализировать информацию о собственниках и их долях владения перед проведением собрания, выберите нужное количество актуализаций в зависимости от планируемого количества собраний.',
  actualizeTooltip2: 'Мы обновим данные и предоставим Вам актуальный реестр собственников помещений.',
  actualizeTooltip3: 'В актуальный реестр не входит информация с контактными данными собственников: e-mail, телефон, документы, подтверждающие личность.',
  actualizeTooltip4: 'Вы сможете выбрать и оплатить этот функционал позднее, когда будете проводить собрание.',
  actualizeTooltip5: 'Вы не можете выбрать услугу по Актуализации реестра. Сначала Вы должны Заказать и оплатить реестр собственников. После этого Вы сможете выбрать Актуализацию реестра.',
  actualizeTooltip6: 'Дело в том, что первоначальный Заказ реестра стоит в два раза дороже, чем Актуализация реестра.',
  actualizeTooltip7: 'Перейти к Заказу реестра',
  payUpLimits: 'Докупить лимиты',
  currentTariff: 'Действующий тариф: ',
  modalTitle1: 'Мы не знаем количество помещений',
  modalTitle2: 'В настройках не указано количество помещений',
  cantBuy: 'Вы не можете сейчас оплатить тариф, так как ',
  forCondo1: 'нам неизвестно количество помещений в Вашем МКД.',
  forCondo2: ' для одного или нескольких МКД, входящих в Ваш Объект, нам не известно количество помещений.',
  tariffsDepend: ' Тарифы зависят от этих показателей.',
  need: 'Необходимо:',
  settings: 'Настройки',
  noNumber1: '1. Перейти в ',
  noNumber2: ' и вписать там ',
  noNumber11: 'кадастровый номер МКД.',
  noNumber12: 'кадастровые номера для каждого МКД.',
  noNumber3: '2. Мы запросим выписку из ЕГРН по Вашему дому',
  noNumber33: '2. Мы запросим выписку из ЕГРН по каждому дому',
  noNumber4: ' и узнаем точное количество всех помещений. Это может занять до {{count}} рабочих дней.',
  noNumber5: '3. После этого Вы сможете на той же странице ',
  noNumber6: ' указать общее количество помещений для МКД.',
  noNumber66: ' указать общее количество помещений для каждого МКД.',
  noNumber7: 'После этого Вам будут доступны платные тарифы',
  close: 'Закрыть',
  goToSettings: 'Перейти в Настройки',
  structure1: '1. Мы запросили выписки из ЕГРН',
  structure2: 'по всем МКД',
  structure3: ', и скоро узнаем точное количество всех помещений. Это может занять до {{count}} рабочих дней. Мы пришлем Вам сообщение с файлом.',
  structure4: '2. После этого Вы сможете на странице ',
  settings1: 'в Настройках МКД не указано количество помещений.',
  settings2: 'в Настройках не указано количество помещений для одного или нескольких МКД, входящих в Ваш Объект.',
  page: '1. На странице ',
  apartmentsCount1: ' укажите общее количество помещений для Вашего МКД.',
  apartmentsCount2: ' укажите общее количество помещений для каждого МКД.',
  customMeetings: 'Собрания',
  customPolls: 'Опросы',
  smsAndEmails: 'Пакеты sms/e-mail рассылок',
  actualization: 'Актуализация реестра собственников',
  pollsTooltip1: 'Вы можете провести опросы без использования других лимитов.',
  pollsTooltip2: 'Формирование опроса;',
  pollsTooltip3: 'Автоматические рассылки;',
  pollsTooltip4: 'Подсчет голосов и итоги голосования.',
  pollsTooltip5: 'Самостоятельные e-mail и sms рассылки',
  limitUnitStorageGb: 'Гб',
  limitUnitStorageMb: 'Мб',
  limitUnitStorageKb: 'Кб',
  limitUnitStorageBytes: 'байт',
  villageModal1: 'Вы не можете сейчас оплатить тариф, так как нам неизвестно количество строений в Вашем поселке. Тарифы зависят от этого.',
  villageModal2: ' укажите общее количество строений для поселка.',
  disabledTitle: 'Сначала выберите лимиты',
  pepTooltipTitle: 'ПЭП (простая электронная подпись)',
  pepTooltipText1: 'ПЭП используется как средство дополнительной аутентификации участников собрания.',
  pepTooltipText2: 'Как это работает?',
  pepTooltipText3: 'Участнику собрания на его номер мобильного телефона, который был ранее <span style="font-weight: 600">сохранен и подтвержден </span> в его профиле высылается СМС сообщение с проверочным кодом. Только после введения этого кода результаты голосования участника будут сохранены.',
  pepTooltipText4: '<span style="font-weight: 600; color: #EC586A">ВАЖНО!!!</span> Это означает, что электронно проголосовать смогут <span style="font-weight: 600">только</span> пользователи с <span style="font-weight: 600">верифицированными номерами мобильных телефонов</span>. От остальных пользователей система будет требовать сначала верифицировать свой номер мобильного телефона.',
  pepTooltipText6: 'У каждого пользователя будет возможность дважды проголосовать бесплатно в собрании. За третье и все  последующие голосования пользователь должен будет заплатить самостоятельно.',
  pepTooltipPriceText: '<span style="font-weight: 600; color: #EC586A">ВАЖНО!!!</span> Это платная опция. Стоимость для Вашего собрания - {{price}} рублей.',
  pepTooltipNoPriceText: 'Стоимость опции не может быть рассчитана до момента, пока не заполнен кадастровый номер и количество помещений в настройках.',
  nameOfPEPLimit: 'Двойная аутентификация (ПЭП) при голосовании на собрании.',
  notificationDataSavedToastText: 'Вы не завершили отправку уведомления. Ваше сообщение и список получателей сохранены и будут отображены в окне уведомления, если вы позже продолжите отправку уведомления.'
};